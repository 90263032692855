import React, { useEffect } from "react";
import MenuHamburgerIcon from "../../Icons/MenuHamburgerKomhendo.svg";
import SearchIcon from "../../Icons/SearchKomhendo.svg";
import { useCartStore, useHomeStore } from "../../Store";
import KomhendoLogo from "../../Icons/Ko.MhendoLogo.svg";
import { Link } from "react-router-dom";

import BagIcon from "../../Icons/BagKomhendo.svg";
import UserIcon from "../../Icons/UserKomhendo.svg";


export default function SmNavBar() {
  const dropdowns = useHomeStore((state) => state.dropdowns);
  const setSidebar = useHomeStore((state) => state.setSidebar);
  const setDropdowns = useHomeStore((state) => state.setDropdowns);
  const handleDropdownClick = (value) => {
    if (dropdowns === value) setDropdowns(0);
    else setDropdowns(value);
  };

  const cart = useCartStore((state) => state.cart);
  const cartItemsNumber = useCartStore((state) => state.cartItemsNumber);
  const cartItemsPrice = useCartStore((state) => state.cartItemsPrice);


  useEffect(() => {
    const cartDetails = { cart, cartItemsNumber, cartItemsPrice };
    localStorage.setItem("cartDetails", JSON.stringify(cartDetails));
  }, [cart, cartItemsNumber, cartItemsPrice]);

  return (
    <div className="smNavBarContainer smContainer">
      <Link className="smNavBarLogoLeft Jomolhari">
        <img src={KomhendoLogo} className="smKomhendoLogoImage" alt="" />
      </Link>
      <div className="smNavBarItemsRight">
        {/* <li className="menuRightChild list-none" onClick={() => setSidebar(2)}>
          <img src={UserIcon} alt="user" />
        </li>
        <li className="menuRightChild cartIcon list-none " onClick={() => setSidebar(3)}>
          <img src={BagIcon} alt="ag" />
          {cartItemsNumber > 0 && (
            <span className="cartItemsNumber">{cartItemsNumber}</span>
          )}
        </li> */}
        <img src={SearchIcon} alt="search" onClick={() => setDropdowns(4)} />
        <img src={MenuHamburgerIcon} alt="menu" onClick={() => setSidebar(1)} />
      </div>
    </div>
  );
}
