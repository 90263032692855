// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/fonts/Jomolhari-alpha3c-0605331.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuShown {
  height: 100vh;
  overflow: hidden;
}

/* Jomalhari Italic */

@font-face {
  font-family: 'Jomalhari';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
  font-weight: 400;
  font-style: normal;
} 
  

/* Jomalhari Regular */
/* @font-face {
  font-family: 'Jomalhari';
  src: url('../../public/fonts/BonVivantSerif.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
} */

/* Didot Regular */
/* @font-face {
  font-family: 'Didot';
  src: url('../../public/fonts/BonVivantSerif.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
} */`, "",{"version":3,"sources":["webpack://./src/Styles/Layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA,qBAAqB;;AAErB;EACE,wBAAwB;EACxB,+DAA+E;EAC/E,gBAAgB;EAChB,kBAAkB;AACpB;;;AAGA,sBAAsB;AACtB;;;;;GAKG;;AAEH,kBAAkB;AAClB;;;;;GAKG","sourcesContent":[".menuShown {\r\n  height: 100vh;\r\n  overflow: hidden;\r\n}\r\n\r\n/* Jomalhari Italic */\r\n\r\n@font-face {\r\n  font-family: 'Jomalhari';\r\n  src: url('../../public/fonts/Jomolhari-alpha3c-0605331.ttf') format('opentype');\r\n  font-weight: 400;\r\n  font-style: normal;\r\n} \r\n  \r\n\r\n/* Jomalhari Regular */\r\n/* @font-face {\r\n  font-family: 'Jomalhari';\r\n  src: url('../../public/fonts/BonVivantSerif.ttf') format('opentype');\r\n  font-weight: 600;\r\n  font-style: normal;\r\n} */\r\n\r\n/* Didot Regular */\r\n/* @font-face {\r\n  font-family: 'Didot';\r\n  src: url('../../public/fonts/BonVivantSerif.ttf') format('opentype');\r\n  font-weight: 400;\r\n  font-style: normal;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
