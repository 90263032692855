import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import jsonData from "../../JsonFiles/products.json";
import AccordionRetract from "../../Icons/AccordionRetract.svg";
import AccordionExpand from "../../Icons/AccordionExpand.svg";
import { useCartStore, useHomeStore } from "../../Store";
import { customAxios } from "../../Api/customAxios";

export default function ProductDisplay() {

  const [product, setProduct] = useState()

  const location = useLocation();
  const setSidebar = useHomeStore((state) => state.setSidebar);
  const setDropdowns = useHomeStore((state) => state.setDropdowns);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("productId");
  const addCartProduct = useCartStore((state) => state.addCartProduct);
  const [imageZoomClickCounter, setImageZoomClickCounter] = useState(-1);
  const [touchStart, setTouchStart] = useState(null);
  const [isImageEnlarged, setIsImageEnlarged] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  const [touchEnd, setTouchEnd] = useState(null);
  const [matchingProduct, setMatchingProduct] = useState(null);
  const [activeSliderId, setActiveSliderId] = useState([]);
  const [activeColorId, setActiveColorId] = useState([]);
  const [activeSizeId, setActiveSizeId] = useState([]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [accordionStates, setAccordionStates] = useState({
    description: true,
    shippingReturn: false,
    packaging: false,
  });

  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [clickStart, setClickStart] = useState(0);

  const imageRef = useRef(null);
  const containerRef = useRef(null);



  useEffect(() => {
    setSidebar(0);
    setDropdowns(0);
  }, []);

  const handleMouseDown = (e) => {
    if (e.button !== 0) return; // Ensure left mouse button
    setDragStartX(e.clientX);
    setDragStartY(e.clientY);
    setClickStart(Date.now());
    setIsDragging(false); // Initially, it's not dragging
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (Date.now() - clickStart < 200) {
      // Check if it's potentially a click
      const moveY = Math.abs(e.clientY - dragStartY);
      if (moveY > 5) {
        // Threshold for drag start
        setIsDragging(true);
      }
    }

    const currentZoomLevel = imageZoomClickCounter === 0
        ? 1.9 // Zoom level when in "zoomed" state
        : imageZoomClickCounter === 1
          ? 2.7 // Zoom level when in "zoomedX2" state
          : 1; // Default zoom level (no zoom)



    if (isDragging && imageZoomClickCounter !== -1) {
      const deltaY = e.clientY - dragStartY;
      const deltaX = e.clientX - dragStartX;
      const dragSpeedFactor = 0.5; // Adjust this factor to control drag speed (less than 1 to slow down)

      const container = containerRef.current;
      const image = imageRef.current;

      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;
      const imageWidth = image.offsetWidth * currentZoomLevel;
      const imageHeight = image.offsetHeight * currentZoomLevel;

      const maxTranslateX = (imageWidth - containerWidth) / 2;
      const maxTranslateY = (imageHeight - containerHeight) / 2;

      
      if (!container || !image) return;

      // setTranslateY((prevY) => prevY + deltaY * dragSpeedFactor);
      // setDragStartY(e.clientY);

      // setTranslateX((prevX) => prevX + deltaX * dragSpeedFactor);
      // setDragStartX(e.clientX);


      setTranslateX((prevX) => {
        const nextX = prevX + deltaX * dragSpeedFactor;
        return Math.min(maxTranslateX, Math.max(-maxTranslateX, nextX));
      });

      setTranslateY((prevY) => {
        const nextY = prevY + deltaY * dragSpeedFactor;
        return Math.min(maxTranslateY, Math.max(-maxTranslateY, nextY));
      });

      setDragStartX(e.clientX);
      setDragStartY(e.clientY);
    }
  };

  const handleMouseUp = (e) => {
    if (!isDragging && Date.now() - clickStart < 100) {
      // Considered a click
      handleIsImageZoomed(e); // Pass the event object to handleIsImageZoomed
    }
    setIsDragging(false);
  };

  const getProduct = async () => {
    try {
      const allProducts = await customAxios.get(`/products/${id}?populate[productImages][populate][image]=*&populate[productImages][populate][color]=*&populate[productCategory]=*&populate[productParentCollection]=*&populate[productColorOptions][populate][colors]=*&populate[productType]=*&populate[productSize]=*&populate[productShipping]=*&populate[productReturn]=*&populate[productPackaging][populate][images]=*`);

      // console.log("dataatproductdisplay", allProducts)
      const myproducts = allProducts?.data?.data;
      // console.log("myproductsdataatproductdisplay", myproducts)

      if (myproducts) {
        setProduct(myproducts)
        const initialColorId = myproducts?.attributes?.productColorOptions?.data[0].id;
        // console.log("initial color id is ", initialColorId)
        // Filter images by the initial color ID
        const filteredImages = myproducts?.attributes?.productImages?.filter(
          (image) => image?.color?.data?.id === initialColorId
        );

        // console.log("filtered images", filteredImages)

        // Update state with the product and filtered images
        setMatchingProduct(myproducts);
        setMatchingProduct((prev) => ({
          ...prev,
          attributes: {
            ...prev.attributes,
            productImages: filteredImages
          }
        }))

        // console.log(matchingProduct)

        // Set the initial active color, size, etc.
        setActiveColorId(initialColorId);
        setActiveSliderId(myproducts.attributes.productImages[0].id);
        setActiveColorId(myproducts.attributes.productColorOptions.data[0].id);
        setActiveSizeId(myproducts.attributes.productSize[0].id);
        setActiveImageIndex(0);
      }


    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // Find matching product in jsonData based on id
    // const product = jsonData.find((product) => product.id === id);
    // if (product) {
    //   const initialColorId = product.productColorOptions[0].colorId;

    //   // Filter images by the initial color ID
    //   const filteredImages = product.productImages.filter(
    //     (image) => image.imageColorId === initialColorId
    //   );


    //   // Update state with the product and filtered images
    //   setMatchingProduct({
    //     ...product,
    //     productImages: filteredImages,
    //   });

    //   // Set the initial active color, size, etc.
    //   setActiveColorId(initialColorId);
    //   setActiveSliderId(product.productImages[0].imageId);
    //   setActiveColorId(product.productColorOptions[0].colorId);
    //   setActiveSizeId(product.productSize[0].sizeId);
    //   setActiveImageIndex(0);
    // }
    getProduct();
  }, [id]);

  useEffect(() => {
    if (!matchingProduct) return; // Ensure there is a product loaded

    // Filter images by the new active color ID
    // const filteredImages = matchingProduct?.attributes?.productImages?.map((image) => {
    //   return image?.color?.data?.id === activeColorId;
    // })

    // console.log(activeColorId, "activeColorId")

    // console.log("the complete product looks like", product)

    const filteredImages = product?.attributes?.productImages?.filter(
      (image) => image?.color?.data?.id === activeColorId
    );

    // console.log("new filtered images is ", filteredImages)

    // Update the matchingProduct state with the new images
    // setMatchingProduct((prevState) => ({
    //   ...prevState,
    //   productImages: filteredImages || [],
    // }));

    setMatchingProduct((prev) => ({
      ...prev,
      attributes: {
        ...prev.attributes,
        productImages: filteredImages || []
      }
    }))

    // console.log("new images with filtered color", matchingProduct)
    // Optionally, reset the slider to the first image of the new color
    if (filteredImages && filteredImages?.length > 0) {
      setActiveSliderId(filteredImages[0].id);
      setActiveImageIndex(0);
    }
  }, [activeColorId]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      // Optionally, reset translateX and translateY to 0 or implement bounds checking
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    console.log(imageZoomClickCounter);
  }, [imageZoomClickCounter]);

  const handleAddToCart = () => {
    const productColorOption = matchingProduct?.attributes?.productColorOptions?.data?.filter(
      (colorOption) => colorOption.id === activeColorId
    )[0];
    const productSize = matchingProduct?.attributes?.productSize.filter(
      (size) => size.id === activeSizeId
    )[0];
    // console.log(productSize)
    const addedProduct = {
      id,
      productColorOption: productColorOption?.attributes?.colorName,
      productSize: productSize?.sizeTag,
      productName: matchingProduct?.attributes?.productName,
      productImage: matchingProduct?.attributes?.productImages[0]?.image?.data?.attributes?.url,
      productPrice: matchingProduct?.attributes?.productPrice,
    };
    // console.log(addedProduct)
    addCartProduct(addedProduct);
  };

  if (!matchingProduct) {
    return (
      <div className="container Jomolhari d-flex items-center justify-center font-bold">
        Loading...
      </div>
    );
  }

  // Add your JSX to display the product here using the matchingProduct object
  const handleSliderClick = (imageId) => {
    const newIndex = matchingProduct?.attributes?.productImages.findIndex(
      (image) => image.id === imageId
    );
    setActiveImageIndex(newIndex);
    setActiveSliderId(imageId);
  };

  const handleColorClick = (colorId) => {
    setActiveColorId(colorId);
  };

  const handleSizeClick = (sizeId) => {
    setActiveSizeId(sizeId);
  };

  const handleLeftPointerClick = () => {
    const currentIndex = matchingProduct?.attributes?.productImages.findIndex(
      (image) => image.id === activeSliderId
    );
    const previousIndex =
      (currentIndex - 1 + matchingProduct?.attributes?.productImages?.length) %
      matchingProduct?.attributes?.productImages?.length;
    setActiveSliderId(matchingProduct?.attributes?.productImages[previousIndex].id);
  };

  const handleRightPointerClick = () => {
    const currentIndex = matchingProduct?.attributes?.productImages.findIndex(
      (image) => image.id === activeSliderId
    );
    const nextIndex = (currentIndex + 1) % matchingProduct?.attributes?.productImages?.length;
    setActiveSliderId(matchingProduct?.attributes?.productImages[nextIndex].id);
  };

  const handleAccordionBtnClick = (accordion) => {
    console.log("called");
    console.log(accordionStates);
    setAccordionStates((prevState) => ({
      ...prevState,
      [accordion]: !prevState[accordion],
    }));
  };

  // const createColorIcon = (colorCode, colorId) => {
  //   const isActive = colorId === activeColorId;
  //   const style = {
  //     backgroundColor: colorCode,
  //     width: "43px",
  //     height: "43px",
  //     border: isActive ? "1px solid #e2e2e2" : "none", // Set border to none initially
  //   };

  //   const outerBorderStyle = isActive ? "1px solid #909090" : "none"; // Outer border style

  //   return (
  //     <div
  //       key={colorId}
  //       className={`productDisplayColorSquare ${isActive ? "active" : ""}`}
  //       style={{ border: outerBorderStyle }}
  //     >
  //       <div style={style} onClick={() => handleColorClick(colorId)}></div>
  //     </div>
  //   );
  // };

  const createColorIcon = (colorCodes, colorId) => {
    const isActive = colorId === activeColorId;
    const colorCount = colorCodes?.length;
    // console.log(colorCodes, "colorcodes")
    // Styles for the container of the color segments
    const containerStyle = {
      display: "flex",
      width: "43px",
      height: "43px",
      border: isActive ? "2px solid #e2e2e2" : "1px solid transparent", // Adjust border for active state
    };

    // Function to create segment styles
    const createSegmentStyle = (color) => ({
      backgroundColor: color,
      flex: 1, // This ensures each color segment takes up equal space
    });

    return (
      <div
        key={colorId}
        className={`productDisplayColorSquare ${isActive ? "active" : ""}`}
        onClick={() => handleColorClick(colorId)}
      >
        <div style={containerStyle}>
          {colorCodes?.map((color, index) => (
            <div key={color.id} style={createSegmentStyle(color?.colorCode)}></div>
          ))}
        </div>
      </div>
    );
  };

  const handleSwipe = (startX, endX) => {
    const threshold = 50; // Minimum swipe distance threshold

    if (startX - endX > threshold) {
      handleRightPointerClick();
    } else if (endX - startX > threshold) {
      handleLeftPointerClick();
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (touchStart) {
      setTouchEnd(e.touches[0].clientX);
    }
  };

  const handleTouchEnd = () => {
    if (touchStart && touchEnd) {
      handleSwipe(touchStart, touchEnd);
      setTouchStart(null);
      setTouchEnd(null);
    }
  };

  const sendMail = () => {
    const email = "example@example.com";
    const subject = "Inquiry about product";
    const body = "Your message here";
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const handleIsImageZoomed = () => {
    console.log("Zoomed", imageZoomClickCounter)
    // Check if the event target has the specific class indicating it's an image meant for zooming
    // Your existing logic to toggle zoom
    if (imageZoomClickCounter < 1) {
      setImageZoomClickCounter(imageZoomClickCounter + 1);
    } else {
      setImageZoomClickCounter(-1);
      setTranslateX(0);
      setTranslateY(0);
    }
  };

  const EnlargedImageDiv = () => {
    return (
      <div className="enlargedImageContainer">
        <div className="functionalitiesWrapper">
          <div
            className={`closeEnlargedImageDiv  `}
            onClick={() => {
              setIsImageEnlarged(false);
            }}
          >
            <svg
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="closeEnlargedImageSvg"
            >
              <path
                d="M27 47.25C24.3407 47.25 21.7075 46.7262 19.2507 45.7086C16.7938 44.6909 14.5615 43.1993 12.6811 41.3189C10.8007 39.4385 9.3091 37.2062 8.29144 34.7493C7.27378 32.2925 6.75 29.6593 6.75 27C6.75 24.3407 7.27378 21.7075 8.29144 19.2507C9.3091 16.7938 10.8007 14.5615 12.6811 12.6811C14.5615 10.8007 16.7938 9.3091 19.2507 8.29144C21.7075 7.27378 24.3407 6.75 27 6.75C29.6593 6.75 32.2925 7.27378 34.7493 8.29144C37.2062 9.3091 39.4385 10.8007 41.3189 12.6811C43.1993 14.5615 44.6909 16.7938 45.7086 19.2507C46.7262 21.7075 47.25 24.3407 47.25 27C47.25 29.6593 46.7262 32.2925 45.7086 34.7493C44.6909 37.2062 43.1993 39.4385 41.3189 41.3189C39.4385 43.1993 37.2062 44.6909 34.7493 45.7086C32.2925 46.7262 29.6593 47.25 27 47.25L27 47.25Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M20.25 20.25L33.75 33.75"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M33.75 20.25L20.25 33.75"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
          {matchingProduct?.attributes?.productImages?.length > 1 && (
            <div
              className={`enlargedScreenPointer lgOnlyElement `}
            >
              <div
                className={`leftPointerProductDisplayEnlarged ${activeSliderId === matchingProduct?.attributes?.productImages[0].id
                  ? "hidden"
                  : ""
                  }`}
                onClick={handleLeftPointerClick}
              >
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="64"
                    y="64"
                    width="64"
                    height="64"
                    transform="rotate(-180 64 64)"
                    fill="white"
                    fillOpacity="0.5"
                  />
                  <path d="M36.5 41L27.5 32L36.5 23" stroke="#222222" />
                </svg>
              </div>
              <div
                className={`rightPointerProductDisplayEnlarged ${activeSliderId ===
                  matchingProduct?.attributes?.productImages[
                    matchingProduct?.attributes?.productImages?.length - 1
                  ].id
                  ? "hidden"
                  : ""
                  }`}
                onClick={handleRightPointerClick}
              >
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="64" height="64" fill="white" fillOpacity="0.5" />
                  <path d="M27.5 23L36.5 32L27.5 41" stroke="#222222" />
                </svg>
              </div>
            </div>
          )}
          <div className="zoomImageWrapper" ref={containerRef} >
            <div
              ref={imageRef}
              className={`imagesProductDisplay enlargedImages`}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              style={{
                transform: `translate(${translateX}px, ${translateY}px)`,
                cursor: isDragging ? "grabbing" : "zoom-in", // Use 'pointer' to indicate clickable
                transition: isDragging ? "none" : "transform 0.3s ease",

                position: "relative"
              }}
            >
              {matchingProduct?.attributes?.productImages.map((image, index) => (
                <img
                  key={image.id}

                  src={`${image?.image?.data?.attributes?.url}`}
                  alt={matchingProduct?.attributes?.productName}
                  className={`enlargedImage imageProductDisplay  ${activeSliderId === image.id ? "active" : ""
                    }  ${imageZoomClickCounter === 0
                      ? "zoomed"
                      : imageZoomClickCounter === 1
                        ? "zoomedX2"
                        : ""
                    }`}
                  onClick={handleIsImageZoomed}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      id="productDisplayContainer"
      className={`productDisplayContainer container ${isBlurred ? "blurred" : ""
        }`}
    >
      {isImageEnlarged && <EnlargedImageDiv />}
      <div className="smProductDisplayTop">
        <div className="productDisplayBreadCrumbs Inter">
          {matchingProduct ? (
            <>
              {matchingProduct?.attributes?.productCategory?.data?.attributes?.categoryName} /
              {matchingProduct?.attributes?.productType?.data?.attributes?.productType}
            </>
          ) : (
            <span>Loading...</span> // Or some placeholder when the product data is not available yet
          )}
        </div>
        <div className="productDisplayTitle Jomolhari">
          {matchingProduct?.attributes?.productName}
        </div>
        {/* <div className="productDisplayPrice Inter-Semibold">
          ${matchingProduct?.attributes?.productPrice}
        </div> */}
      </div>
      <div className="productDisplayLeft">
        <div
          className="enlargeImageIcon lgOnlyElement"
          onClick={() => setIsImageEnlarged(true)}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="enlargeImageIconSvg"
          >
            <rect width="48" height="48" fill="white" fillOpacity="0.2" />
            <g opacity="0.5">
              <path
                d="M12 12V11.5H11.5V12H12ZM23.6464 24.3536C23.8417 24.5488 24.1583 24.5488 24.3536 24.3536C24.5488 24.1583 24.5488 23.8417 24.3536 23.6464L23.6464 24.3536ZM12.5 21V12H11.5V21H12.5ZM12 12.5H21V11.5H12V12.5ZM11.6464 12.3536L23.6464 24.3536L24.3536 23.6464L12.3536 11.6464L11.6464 12.3536Z"
                fill="#222222"
              />
              <path
                d="M12 36V36.5H11.5V36H12ZM23.6464 23.6464C23.8417 23.4512 24.1583 23.4512 24.3536 23.6464C24.5488 23.8417 24.5488 24.1583 24.3536 24.3536L23.6464 23.6464ZM12.5 27V36H11.5V27H12.5ZM12 35.5H21V36.5H12V35.5ZM11.6464 35.6464L23.6464 23.6464L24.3536 24.3536L12.3536 36.3536L11.6464 35.6464Z"
                fill="#222222"
              />
              <path
                d="M36 12V11.5H36.5V12H36ZM24.3536 24.3536C24.1583 24.5488 23.8417 24.5488 23.6464 24.3536C23.4512 24.1583 23.4512 23.8417 23.6464 23.6464L24.3536 24.3536ZM35.5 21V12H36.5V21H35.5ZM36 12.5H27V11.5H36V12.5ZM36.3536 12.3536L24.3536 24.3536L23.6464 23.6464L35.6464 11.6464L36.3536 12.3536Z"
                fill="#222222"
              />
              <path
                d="M36 36V36.5H36.5V36H36ZM24.3536 23.6464C24.1583 23.4512 23.8417 23.4512 23.6464 23.6464C23.4512 23.8417 23.4512 24.1583 23.6464 24.3536L24.3536 23.6464ZM35.5 27V36H36.5V27H35.5ZM36 35.5H27V36.5H36V35.5ZM36.3536 35.6464L24.3536 23.6464L23.6464 24.3536L35.6464 36.3536L36.3536 35.6464Z"
                fill="#222222"
              />
            </g>
          </svg>
        </div>
        {matchingProduct?.attributes?.productImages?.length > 1 && (
          <div className="pointersProductDisplay lgOnlyElement">
            <div
              className={`leftPointerProductDisplay ${activeSliderId === matchingProduct?.attributes?.productImages[0].id
                ? "hidden"
                : ""
                }`}
              onClick={handleLeftPointerClick}
            >
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="64"
                  y="64"
                  width="64"
                  height="64"
                  transform="rotate(-180 64 64)"
                  fill="white"
                  fillOpacity="0.5"
                />
                <path d="M36.5 41L27.5 32L36.5 23" stroke="#222222" />
              </svg>
            </div>
            <div
              className={`rightPointerProductDisplay ${activeSliderId ===
                matchingProduct?.attributes?.productImages[
                  matchingProduct?.attributes?.productImages?.length - 1
                ].id
                ? "hidden"
                : ""
                }`}
              onClick={handleRightPointerClick}
            >
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="64" height="64" fill="white" fillOpacity="0.5" />
                <path d="M27.5 23L36.5 32L27.5 41" stroke="#222222" />
              </svg>
            </div>
          </div>
        )}
        {matchingProduct?.attributes?.productImages?.length > 1 && (
          <div className="slidersProductDisplay">
            {matchingProduct?.attributes?.productImages.map((image, index) => (
              <div
                key={image.id}
                className={`sliderProductDisplay ${activeSliderId === image.id ? "active" : ""
                  }`}
                onClick={() => handleSliderClick(image.id)}
              ></div>
            ))}
          </div>
        )}
        <div className="imagesProductDisplay">
          {matchingProduct?.attributes?.productImages.map((image, index) => (
            <img
              key={image.id}
              src={`${image?.image?.data?.attributes?.url}`}
              alt={matchingProduct?.attributes?.productName}
              className={`imageProductDisplay ${activeSliderId === image.id ? "active" : ""
                }`}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            />
          ))}
        </div>
      </div>

      <div data-scroll-section className="productDisplayRight">
        <div className="productDisplayBreadCrumbs Inter lgOnlyElement">
          {matchingProduct?.attributes?.productCategory?.data?.attributes?.categoryName} /{" "}
          {matchingProduct?.attributes?.productType?.data?.attributes?.productType}
        </div>
        <div className="productDisplayTitle Jomolhari lgOnlyElement">
          {matchingProduct?.attributes?.productName}
        </div>
        {/* <div className="productDisplayPrice Inter-Semibold lgOnlyElement">
          ${matchingProduct.productPrice}
        </div> */}
        <div className="productDisplayColorOptions">
          {matchingProduct?.attributes?.productColorOptions?.data?.map((color, colorIndex) => (
            <div
              key={color.id}
              className={`productDisplayColorName  ${color.id === activeColorId ? "active" : ""
                }`}
            >
              <span className="Inter">Color:</span>
              <span className="Inter-Semibold">{color.attributes?.colorName}</span>{" "}
            </div>
          ))}
          <div className="productDisplayColorPicker">
            {matchingProduct?.attributes?.productColorOptions?.data?.map((color, colorIndex) =>
              createColorIcon(color?.attributes?.colors, color?.id)
            )}
          </div>
        </div>
        <div className="productDisplaySizeOptions">
          <div className="productDisplaySizeOptionsTitle Inter-Semibold">
            Size
          </div>
          <div className="productDisplaySizeOptionsPicker">
            {matchingProduct?.attributes?.productSize.map((size, sizeIndex) => (
              <div
                key={size.id}
                className={`productDisplaySize Inter ${size.id === activeSizeId ? "active" : ""
                  }`}
                onClick={() => handleSizeClick(size.id)}
              >
                {size.sizeTag}
              </div>
            ))}
          </div>
        </div>
        <div className="productDisplayAccordion">
          <div className="productDisplayAccordionElement">
            <div className="productDisplayAccordionTitleAndInteractionButton">
              <div className="productDisplayAccordionTitle Inter-Semibold">
                Description
              </div>
              <div className="accordionInteractionButtons">
                <img
                  className={`retractBtn ${accordionStates["description"] ? "active" : ""
                    }`}
                  src={AccordionRetract}
                  alt=""
                  onClick={() => handleAccordionBtnClick("description")}
                />
                <img
                  className={`expandBtn ${accordionStates["description"] ? "" : "active"
                    }`}
                  src={AccordionExpand}
                  alt=""
                  onClick={() => handleAccordionBtnClick("description")}
                />
              </div>
            </div>
            <div
              className={`productDisplayAccordionContent ${accordionStates["description"] ? "active" : ""
                }`}
            >
              {matchingProduct?.attributes?.productDescription}
            </div>
          </div>
          <div className="productDisplayAccordionElement">
            <div className="productDisplayAccordionTitleAndInteractionButton">
              <div className="productDisplayAccordionTitle Inter-Semibold">
                Shipping & Return
              </div>
              <div className="accordionInteractionButtons">
                <img
                  className={`retractBtn ${accordionStates["shipping"] ? "active" : ""
                    }`}
                  src={AccordionRetract}
                  alt=""
                  onClick={() => handleAccordionBtnClick("shipping")}
                />
                <img
                  className={`expandBtn ${accordionStates["shipping"] ? "" : "active"
                    }`}
                  src={AccordionExpand}
                  alt=""
                  onClick={() => handleAccordionBtnClick("shipping")}
                />
              </div>
            </div>
            <div
              className={`productDisplayAccordionContent ${accordionStates["shipping"] ? "active" : ""
                }`}
            >
              <div className="shippingPart">
                <div className="ShippingTitle Inter-Medium">Shipping</div>
                <ul className="shippingDescriptionPoints">
                  {matchingProduct?.attributes?.productShipping.map((points) => (
                    <li
                      key={points.id}
                      className="shippingDescriptionPoint"
                    >
                      {points.shippingDesc}
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="returnPart">
                <div className="ShippingTitle Inter-Medium">Return</div>
                <ul className="shippingDescriptionPoints">
                  {matchingProduct.productReturn.map((points) => (
                    <li
                      key={points.returnDescId}
                      className="shippingDescriptionPoint"
                    >
                      {points.returnDesc}
                    </li>
                  ))}
                </ul>
              </div> */}
              {/* <div className="shippingAndReturnSlogan">
                {matchingProduct.productShippingAndReturnSectionTag}
              </div> */}
            </div>
          </div>
          <div className="productDisplayAccordionElement">
            <div className="productDisplayAccordionTitleAndInteractionButton">
              <div className="productDisplayAccordionTitle Inter-Semibold">
                KO_MHENDO Packaging
              </div>
              <div className="accordionInteractionButtons">
                <img
                  className={`retractBtn ${accordionStates["packaging"] ? "active" : ""
                    }`}
                  src={AccordionRetract}
                  alt=""
                  onClick={() => handleAccordionBtnClick("packaging")}
                />
                <img
                  className={`expandBtn ${accordionStates["packaging"] ? "" : "active"
                    }`}
                  src={AccordionExpand}
                  alt=""
                  onClick={() => handleAccordionBtnClick("packaging")}
                />
              </div>
            </div>
            <div
              className={`productDisplayAccordionContent ${accordionStates["packaging"] ? "active" : ""
                }`}
            >
              {matchingProduct?.attributes?.productDescription}
              <div className="imagesSectionProductPackaging">
                {matchingProduct?.attributes?.productPackaging?.images?.data?.map((image) => (
                  <img key={image.id} src={`${image?.attributes?.url}`} />
                ))}
              </div>
            </div>
          </div>
          <div className="productDisplayAccordionElement accordionTerminator"></div>
        </div>
      </div>
      <div className="backgroundForBtn smOnlyElement">
        <button
          onClick={sendMail}
          className="primary gray productDisplayInquireButton Inter-Medium smOnlyElement"
        >
          Inquire
        </button>
      </div>
      {/* <div className="backgroundForBtn smOnlyElement">
        <button
          onClick={handleAddToCart}
          className="primary gray productDisplayInquireButton Inter-Medium smOnlyElement"
        >
          Add to cart
        </button>
      </div> */}



      <div className="backgroundForLgBtn lgOnlyElement">
        <button
          onClick={sendMail}
          className="primary gray productDisplayInquireButton Inter-Medium lgOnlyElement"
        >
          Inquire
        </button>
      </div>
      {/* <div className="backgroundForLgBtn lgOnlyElement">
        <button
          onClick={handleAddToCart}
          className="primary gray productDisplayInquireButton Inter-Medium lgOnlyElement"
        >
          Add to cart
        </button>
      </div> */}
    </div>
  );
}
