import { customAxios } from "./customAxios";

export const getCollections = async () => {
  try {
    const { data } = await customAxios.get("/collections?populate[image]=*");
  return data;
  } catch (error) {
    console.log(error)
  }
};

export const getCollectionsById = async (id) => {
 try {
  const { data } = await customAxios.get(`/collections/${id}?populate[image]=*`);
  return data;
 } catch (error) {
  console.log(error)
 }
};

export const getCategoryMin = async (id) => {
  try {
    const { data } = await customAxios.get(
      `/categories/${id}?populate[products][fields][0]=productName&populate[products][populate][productImages][populate]=*&populate[product_types]=*`
    );
    return data;
  } catch (error) {
    console.log(error)
  }
};
