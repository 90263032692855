// import React, { useState, useEffect } from "react";
// import products from "../../JsonFiles/products.json";
// import { Link } from "react-router-dom";
// import "../../Styles/ProductsCarousel.css";

// export default function ProductsCarousel({
//   sectionTag,
//   sectionTitle,
//   source = "",
// }) {
//   const [matchingProducts, setMatchingProducts] = useState([]);
//   const [activeSliderIds, setActiveSliderIds] = useState([]);
//   const [isDragging, setIsDragging] = useState(false);
//   const [startX, setStartX] = useState(null);
//   const [scrollLeft, setScrollLeft] = useState(null);
//   const [initialColorIds, setInitialColorIds] = useState([]);

//   useEffect(() => {
//     const matchedProducts = products.filter((product) =>
//       product.productAward.includes(sectionTag)
//     );

//     // Extract the first colorId from each matched product and store it in an array
//     const colorIds = matchedProducts.map(
//       (product) => product.productColorOptions[0].colorId
//     );

//     setInitialColorIds(colorIds);

//     const initialActiveSliderIds = matchedProducts.map((product) =>
//       product.productImages.length > 0 ? product.productImages[0].imageId : null
//     );

//     setMatchingProducts(matchedProducts);
//     setActiveSliderIds(initialActiveSliderIds);
//   }, [sectionTag]);

//   const handleMouseDown = (e) => {
//     setIsDragging(true);
//     setStartX(e.pageX - e.currentTarget.offsetLeft);
//     setScrollLeft(e.currentTarget.scrollLeft);
//     if (e.currentTarget.style.cursor === "grabbing") {
//       e.currentTarget.style.cursor = "default";
//     } else {
//       e.currentTarget.style.cursor = "grabbing";
//     }
//   };

//   const handleMouseLeave = () => {
//     setIsDragging(false);
//     document.body.style.cursor = "default";
//   };

//   const handleMouseUp = (e) => {
//     setIsDragging(false);
//     if (e.currentTarget.style.cursor === "default") {
//       e.currentTarget.style.cursor = "grabbing";
//     } else {
//       e.currentTarget.style.cursor = "default";
//     }
//   };

//   const handleMouseMove = (e) => {
//     if (!isDragging) return;
//     e.preventDefault();
//     const x = e.pageX - e.currentTarget.offsetLeft;
//     const walk = (x - startX) * 0.5;
//     e.currentTarget.scrollLeft = scrollLeft - walk;
//   };

//   const handleSliderClick = (containerIndex, imageId) => {
//     setActiveSliderIds((prevActiveIds) => {
//       const newActiveIds = [...prevActiveIds];
//       newActiveIds[containerIndex] = imageId;
//       return newActiveIds;
//     });
//   };

//   return (
//     <div className={`carouselContainer container`}>
//       <div className="carouselHeading">{sectionTitle}</div>
//       <div
//         className="carouselProductsContainer"
//         onMouseDown={handleMouseDown}
//         onMouseLeave={handleMouseLeave}
//         onMouseUp={handleMouseUp}
//         onMouseMove={handleMouseMove}
//       >
//         {matchingProducts.map((matchingProduct, index) => (
//                       <Link
//                       to={`/products?productId=${matchingProduct.productId}`}
//                     >
//           <div key={index} className="carouselProductContainer">
//             <div className="imageContainer">
//               {matchingProduct.productImages.length > 1 && (
//                 <div className="sliders">
//                   {matchingProduct.productImages.map(
//                     (image, imageIndex) =>
//                       initialColorIds.includes(image.imageColorId) && (
//                         <div
//                           key={image.imageId}
//                           className={`slider ${
//                             activeSliderIds[index] === image.imageId
//                               ? "active"
//                               : ""
//                           }`}
//                           onClick={() =>
//                             handleSliderClick(index, image.imageId)
//                           }
//                         ></div>
//                       )
//                   )}
//                 </div>
//               )}
//               <div className="images">
//                 {matchingProduct.productImages.map(
//                   (image, imageIndex) =>
//                     initialColorIds.includes(image.imageColorId) && (
//                       <img
//                         key={image.imageId}
//                         src={image.imageLink}
//                         alt={matchingProduct.productName}
//                         className={`image ${
//                           activeSliderIds[index] === image.imageId
//                             ? "active"
//                             : ""
//                         }`}
//                       />
//                     )
//                 )}
//               </div>
//             </div>
//             <div className="imageTagCarousel Inter">
//               {matchingProduct.productName}
//             </div>

//           </div>
//           </Link>
//         ))}
//       </div>
//     </div>
//   );
// }
import React, { useState, useEffect, useRef } from 'react';
import products from '../../JsonFiles/products.json';
import { Link } from 'react-router-dom';
import '../../Styles/ProductsCarousel.css';
import { customAxios } from '../../Api/customAxios';

export default function ProductsCarousel({
  sectionTag,
  sectionTitle,
  source = '',
}) {
  const [matchingProducts, setMatchingProducts] = useState([]);
  const [activeSliderIds, setActiveSliderIds] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const [initialColorIds, setInitialColorIds] = useState([]);
  const carouselRef = useRef(null); // Ref for the carousel container

  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   const matchedProducts = products.filter((product) =>
  //     product.productAward.includes(sectionTag)
  //   );

  //   const colorIds = matchedProducts.map(
  //     (product) => product.productColorOptions[0].colorId
  //   );

  //   setInitialColorIds(colorIds);

  //   const initialActiveSliderIds = matchedProducts.map((product) =>
  //     product.productImages.length > 0 ? product.productImages[0].imageId : null
  //   );

  //   setMatchingProducts(matchedProducts);
  //   setActiveSliderIds(initialActiveSliderIds);
  // }, [sectionTag]);

  const getProducts = async () => {
    try {
      setLoading(true)
      const allProducts = await customAxios.get("/products?populate[productImages][populate][image]=*&populate[productImages][populate][color]=*&populate[productCategory]=*&populate[productParentCollection]=*&populate[productColorOptions][populate][colors]=*&populate[productType]=*&populate[productSize]=*&populate[productShipping]=*&populate[productReturn]=*&populate[productPackaging]=*");
      // console.log(allProducts)
      const myproducts = allProducts?.data?.data;
      // console.log("myproducts", myproducts)

      const mymatchedProducts = myproducts.filter((product) =>
        product?.attributes?.productAward.includes(sectionTag)
      );

      // myproducts.forEach((item) => {
      //   console.log(item?.attributes?.productAward)
      // })

      // console.log("matched products", mymatchedProducts)

      const mycolorIds = mymatchedProducts.map(
        (product) => product?.attributes?.productColorOptions?.data[0]?.id
      );

      // console.log("initial color ids", mycolorIds)
      setInitialColorIds(mycolorIds);

      const initialActiveSliderIds = mymatchedProducts.map((product) =>
        product?.attributes?.productImages?.length > 0 ? product?.attributes?.productImages[0].id : null
      );

      // console.log(initialActiveSliderIds, "initialActiveSliderw")

      setMatchingProducts(mymatchedProducts);
      setActiveSliderIds(initialActiveSliderIds);
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProducts();
  }, [])

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
    if (e.currentTarget.style.cursor === 'grabbing') {
      e.currentTarget.style.cursor = 'default';
    } else {
      e.currentTarget.style.cursor = 'grabbing';
    }
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
  };

  const handleMouseUp = (e) => {
    setIsDragging(false);
    if (e.currentTarget.style.cursor === 'default') {
      e.currentTarget.style.cursor = 'grabbing';
    } else {
      e.currentTarget.style.cursor = 'default';
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 0.5;
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };

  const handleSliderClick = (containerIndex, imageId) => {
    setActiveSliderIds((prevActiveIds) => {
      const newActiveIds = [...prevActiveIds];
      newActiveIds[containerIndex] = imageId;
      return newActiveIds;
    });
  };

  const handleNextSlider = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: 1221, // adjust the value as per your need
        behavior: 'smooth',
      });
    }
  };

  const handlePrevSlider = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -1221, // adjust the value as per your need
        behavior: 'smooth',
      });
    }
  };

  return (
    <>

      {
        loading ? <div className='carouselLoading' >Loading ...</div> : <div className={`carouselContainer container`}>
          <div className="carouselHeading"><div>
            {sectionTitle &&
              <>
                <span className='Inter-Medium' >
                  {
                    sectionTitle.split("/")[0]
                  }
                </span>
                {
                  sectionTitle.split("/").length > 1 &&
                  <span className='Jomolhari' >
                    / {sectionTitle.split("/")[1]}
                  </span>
                }
              </>
            }
          </div>
            {/* <div className="carouselControls">
        <button className="prevButton Inter" onClick={handlePrevSlider}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            width={24}
            height={24}
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            class="feather feather-arrow-left"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </button>
        <button className="nextButton Inter" onClick={handleNextSlider}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"           
            width={24}
            height={24}
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            class="feather feather-arrow-right"
          >
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <polyline points="12 5 19 12 12 19"></polyline>
          </svg>
        </button>
      </div> */}
          </div>

          <div
            className="carouselProductsContainer"
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            ref={carouselRef} // Attach the ref to the container
          >
            <div className="carouselControls">
              <button className="prevButton Inter" onClick={handlePrevSlider}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  width={24}
                  height={24}
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="feather feather-arrow-left"
                >
                  <line x1="19" y1="12" x2="5" y2="12"></line>
                  <polyline points="12 19 5 12 12 5"></polyline>
                </svg>
              </button>
              <button className="nextButton Inter" onClick={handleNextSlider}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  width={24}
                  height={24}
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </button>
            </div>

            {matchingProducts?.map((matchingProduct, index) => {

              const currentProductInitialColorId = initialColorIds[index];

              return (
                <div className="carouselProductContainer" key={index}>
                  <div className="imageContainer">
                    {matchingProduct?.attributes?.productImages.length > 1 && (
                      <div className="sliders">
                        {matchingProduct?.attributes?.productImages.map(
                          (image, imageIndex) =>
                            image?.color?.data?.id === currentProductInitialColorId && (
                              <div
                                key={image?.color?.data?.id + imageIndex}
                                className={`slider ${activeSliderIds[index] === image?.id
                                  ? 'active'
                                  : ''
                                  }`}
                                onClick={() =>
                                  handleSliderClick(index, image?.id)
                                }
                              ></div>
                            )
                        )}
                      </div>
                    )}
                    <Link
                      to={`/products?productId=${matchingProduct?.id}`}
                      key={index + matchingProduct?.id}
                    >


                      <div className="images">
                        {matchingProduct?.attributes?.productImages.map(
                          (image, imageIndex) =>
                            currentProductInitialColorId === image?.color?.data?.id && (
                              <img
                                key={image?.id + imageIndex}
                                src={`${image?.image?.data?.attributes?.url}`}
                                alt={matchingProduct?.attributes?.productName}
                                className={`image ${activeSliderIds[index] === image?.id
                                  ? 'active'
                                  : ''
                                  }`}
                              />
                            )
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className="imageTagCarousel Inter">
                    {matchingProduct?.attributes?.productName}
                  </div>
                </div>)
            })}
          </div>

        </div>
      }
    </>
  );
}
