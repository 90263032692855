import { customAxios } from "./customAxios";

export const registerUser = async ({
  firstName,
  lastName,
  email,
  password,
}) => {
  // console.log(firstName, lastName, email, password)
  const { data } = await customAxios.post("/auth/local/register", {
    username: lastName ? `${firstName} ${lastName}` : firstName,
    email,
    password,
  });
  return data;
};

export const loginUser = async ({ identifier, password }) => {
  const { data } = await customAxios.post("/auth/local", {
    identifier,
    password,
  });
  if (data.jwt) document.cookie = `jwt=${data.jwt}; path=/`;
  return data;
};
