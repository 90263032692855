import { create } from "zustand";
import { customAxios } from "./Api/customAxios";
import {
  getCart,
  putItemInCart,
  removeCartItem,
  updateCartOnBackend,
} from "./Api/cart";

const isSameProduct = (product1, product2) => {
  return (
    product1.id === product2.id &&
    product1.productColorOption === product2.productColorOption &&
    product1.productSize === product2.productSize
  );
};

export const useHomeStore = create((set) => ({
  sidebar: 0,
  setSidebar: (value) => {
    set(() => ({ sidebar: value }));
  },
  dropdowns: 0,
  setDropdowns: (value) => {
    set(() => ({ dropdowns: value }));
  },
  redirectPath: null, // Stores the path to redirect after login
  setRedirectPath: (path) => {
    set(() => ({ redirectPath: path })); // Store the redirect path
  },
}));

export const useCartStore = create((set) => {
  const storedCartDetails = JSON.parse(localStorage.getItem("cartDetails")) || {
    cart: [],
    cartItemsNumber: 0,
    cartItemsPrice: 0,
  };
  return {
    ...storedCartDetails,
    addCartProduct: async (newCartProduct) => {
      try {
        set((state) => {
          const existingProductIndex = state.cart.findIndex((cartProduct) =>
            isSameProduct(cartProduct.product, newCartProduct)
          );
          if (existingProductIndex === -1) {
            return {
              cart: [
                ...state.cart,
                {
                  product: newCartProduct,
                  productColorOption: newCartProduct.productColorOption,
                  productSize: newCartProduct.productSize,
                  quantity: 1,
                },
              ],
              cartItemsNumber: state.cartItemsNumber + 1,
              cartItemsPrice:
                state.cartItemsPrice + newCartProduct.productPrice,
            };
          }
          const updatedCart = [...state.cart];
          updatedCart[existingProductIndex].quantity++;

          return {
            cart: updatedCart,
            cartItemsNumber: state.cartItemsNumber + 1,
            cartItemsPrice: state.cartItemsPrice + newCartProduct.productPrice,
          };
        });

        // console.log(newCartProduct, "newCartProduct");
        const { isAuthenticated } = useAuthStore.getState();
        if (isAuthenticated) {
          const response = await putItemInCart(
            newCartProduct.id,
            newCartProduct.productColorOption,
            newCartProduct.productSize,
            1
          );
        }

        // console.log("Product added to cart on backend:", response.data);
      } catch (error) {
        console.error("Error adding product to cart:", error);
      }
    },
    removeCartProduct: async (oldCartProduct) => {
      try {
        set((state) => {
          const existingProductIndex = state.cart.findIndex((cartProduct) =>
            isSameProduct(cartProduct.product, oldCartProduct)
          );
          console.log(existingProductIndex);
          if (existingProductIndex !== -1) {
            const updatedCart = [...state.cart];
            if (updatedCart[existingProductIndex].quantity > 1) {
              updatedCart[existingProductIndex].quantity--;
            } else {
              updatedCart.splice(existingProductIndex, 1);
            }
            return {
              cart: updatedCart,
              cartItemsNumber: state.cartItemsNumber - 1,
              cartItemsPrice:
                state.cartItemsPrice - oldCartProduct.productPrice,
            };
          }
          return state;
        });

        // console.log(oldCartProduct, "oldCartProduct");
        // console.log(storedCartDetails.cart, "storedCartDetails.cart");
        // const updatedCart = storedCartDetails.cart.filter((item) => item.product.id !== oldCartProduct.id && item.productColorOption !== oldCartProduct.productColorOption && item.productSize !== oldCartProduct.productSize );
        // console.log(updatedCart, "updatedCart");
        const { isAuthenticated } = useAuthStore.getState();
        if (isAuthenticated) {
          const response = await removeCartItem(
            oldCartProduct.id,
            oldCartProduct.productColorOption,
            oldCartProduct.productSize
          );
        }
        // console.log("Product removed from cart on backend:", response.data);
      } catch (error) {
        console.error("Error removing product from cart:", error);
      }
    },
    fetchCart: async () => {
      // console.log("fetching cart data");
      const { isAuthenticated } = useAuthStore.getState(); // Check authentication status
      if (isAuthenticated) {
        try {
          const response = await getCart();
          // console.log(response, "from cart data fetching");

          if (storedCartDetails.cart.length === 0) {
            set({
              cart: response,
              cartItemsNumber: response.reduce(
                (total, item) => total + item.quantity,
                0
              ),
            });
            return;
          }

          // console.log("stored cart details", storedCartDetails);
          const updatedCart = storedCartDetails.cart.map((localItem) => {
            const matchingBackendItem = response.find(
              (backendItem) =>
                backendItem?.productColorOption ===
                  localItem.productColorOption &&
                backendItem?.productSize === localItem.productSize &&
                backendItem?.product.id === parseInt(localItem.product.id) // Match by product ID
            );
            // console.log("matching backend item", matchingBackendItem);

            if (matchingBackendItem) {
              // Update the id and any other fields if needed
              return {
                ...localItem,
                id: matchingBackendItem.id, // Add backend cart ID
                quantity: matchingBackendItem.quantity,
                createdAt: matchingBackendItem.createdAt, // Example field
                updatedAt: matchingBackendItem.updatedAt, // Example field
              };
            }
            return localItem; // Keep as-is if no match
          });
          // console.log("updated cart", updatedCart);
          const newTotalItems = updatedCart.reduce(
            (total, item) => total + item.quantity,
            0
          );

          const newTotalPrice = updatedCart.reduce(
            (total, item) => total + item.product.productPrice * item.quantity,
            0
          );

          // Update the cart and totalItems in the store
          set({
            cart: updatedCart,
            cartItemsNumber: newTotalItems,
            cartItemsPrice: newTotalPrice,
          });
          // console.log("Fetched cart from backend:");
        } catch (error) {
          console.error("Error fetching cart data:", error);
        }
      } else {
        // If the user is not authenticated, use localStorage
        // console.log("Using local cart data:", storedCartDetails);
        set(storedCartDetails);
      }
    },
    // syncCartOnLogin: async () => {
    //   const { isAuthenticated } = useAuthStore.getState();
    //   if (isAuthenticated) {
    //     await useCartStore.getState().setCartToBackend();
    //   }
    // },
  };
});

export const useAuthStore = create((set) => ({
  user: JSON.parse(localStorage.getItem("user")) || null, // Retrieve the user data from localStorage (or null if not available)
  isAuthenticated: !!localStorage.getItem("user"), // Check if the token is present in localStorage (true or false)

  login: (userData) => {
    set(() => ({
      user: userData,
      isAuthenticated: true,
    }));
    // Optionally, you can store the user's token in localStorage or a cookie
    localStorage.setItem("user", JSON.stringify(userData));
  },
  logout: () => {
    set(() => ({
      user: null,
      isAuthenticated: false,
    }));
    // Optionally, clear the token from localStorage
    document.cookie = `jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    localStorage.removeItem("user");
  },
  setUser: (userData) => {
    localStorage.setItem("user", JSON.stringify(userData));
    set(() => ({
      user: userData,
      isAuthenticated: true,
    }));
  },
  clearUser: () => {
    localStorage.removeItem("user");
    set(() => ({
      user: null,
      isAuthenticated: false,
    }));
  },
}));
