import { useState } from "react";
import SignIn from "./SignIn";
import JoinUs from "./JoinUs";
import { useAuthStore } from "../../Store";

const Account = () => {
  const [accountTab, setAccountTab] = useState(1);

  const user = useAuthStore((state) => state.user)
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const logout = useAuthStore((state) => state.logout);

  const handleTabChange = (value) => {
    if (accountTab === value) return;
    setAccountTab(value);
  };

  if (isAuthenticated) {
    return <>
      <h2>
        Hello {user?.user.firstName} {user?.user.lastname}
      </h2>
      <div className="">
        <span
          className={`${accountTab === 1 ? `active` : ""}`}
          onClick={() => logout()}
        >
          Log Out
        </span>
      </div>
    </>
  }
  return (
    <>
      <div className="accountTabs">
        <span
          className={`${accountTab === 1 ? `active` : ""}`}
          onClick={() => handleTabChange(1)}
        >
          SIGN IN
        </span>
        <span
          className={`${accountTab === 2 ? `active` : ""}`}
          onClick={() => handleTabChange(2)}
        >
          JOIN US
        </span>
      </div>
      {accountTab === 1 && <SignIn />}
      {accountTab === 2 && <JoinUs handleTabChange={handleTabChange} />}
    </>
  );
};
export default Account;
