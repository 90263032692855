import { syncCartToBackend } from "../Api/cart";
import { useCartStore } from "../Store";

export const handleCartSync = async () => {
  const cartItems = useCartStore.getState().cart;

  // console.log("Syncing cart items to the backend:", cartItems);
  // Sync cart items to the backend
  await syncCartToBackend(cartItems);
  
  // console.log("User login successful, cart synced to backend.");
};
