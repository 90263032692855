import React, { useEffect, useState } from 'react';
// import { importImage } from '../../miscellaneousFunc';
import { Link, useNavigate } from 'react-router-dom';
import { getCatalogue } from '../../Api/catalogue';

export default function DownloadCatalogue() {
  const navigate = useNavigate();
  const downloadPDF = (e) => {
    e.preventDefault();

    const link = document.createElement('a');
    link.href = catalogueData?.attributes?.catalogueCTALink; // URL to the PDF
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [catalogueData, setCatalogueData] = useState();

  const getCatalogueData = async () => {
    const response = await getCatalogue();
    console.log(response?.data);
    setCatalogueData(response?.data);
  }

  useEffect(() => {
    getCatalogueData();
  }, [])

  return (
    <div className="downloadCatalogue container">
      <div className="downloadCatalogueLeft">
        <img
          src={`${catalogueData?.attributes?.image?.data?.attributes?.url}`}
          alt={catalogueData?.attributes?.catalogueTitle}
          className="downloadCatalogueImage"
        />
        <div className="smDownloadCatalogueImageAndGradientContainer">
          {catalogueData?.attributes?.smImage?.data?.attributes?.mime !== '' && (
            <div className={` ${catalogueData?.attributes?.smImage?.data?.attributes?.mime}`}></div> //className = graidentTypeFour
          )}
          <img
            src={`${catalogueData?.attributes?.smImage?.data?.attributes?.url}`}
            alt={catalogueData?.attributes?.catalogueTitle}
            className={`smDownloadCatalogueImage`}
          />
        </div>
      </div>
      <div className="downloadCatalogueRight">
        {catalogueData?.attributes?.breadCrumb !== '' && (
          <div className="catalogueBreadcrumb Inter">{catalogueData?.attributes?.breadCrumb}</div>
        )}
        {catalogueData?.attributes?.catalogueTitle !== '' && (
          <div
            className="catalogueBlockTitle Inter-Medium"
            dangerouslySetInnerHTML={{ __html: catalogueData?.attributes?.catalogueTitle }}
          />
        )}
        {catalogueData?.attributes?.catalogueDescription !== '' && (
          <div
            className="catalogueBlockDescription Inter-Light"
            dangerouslySetInnerHTML={{ __html: catalogueData?.attributes?.catalogueDescription }}
          />
        )}
        {catalogueData?.attributes?.buttonText !== '' && (
          <div className="buttonsSection">
            <button
              className={`primary inverted black Inter-Medium catalogueSectionButton`}
              onClick={() =>
                navigate(
                  `${catalogueData?.attributes?.buttonLink}` // URL to the catalogue
                )
              }
            >
              {catalogueData?.attributes?.buttonText}
            </button>
            <Link
              className="catalogueCtaLink Inter"
              to="#"
              onClick={downloadPDF}
            >
              {catalogueData?.attributes?.catalogueCTA}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
