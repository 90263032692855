import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import staticData from "../JsonFiles/staticPage.json";
import "../Styles/StaticPage.css";  // Make sure your CSS path is correct
import { scrollToTop } from '../miscellaneousFunc';

const StaticPage = () => {
  const [staticDataItem, setStaticDataItem] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const pageInUrl = useParams().page.replace(/-/g, '').toLowerCase();

  useEffect(() => {
    scrollToTop();
  }, [])

  useEffect(() => {
    const matchedData = staticData.find(item =>
      item.staticPageId.replace(/[^a-zA-Z0-9&]/g, '').toLowerCase().includes(pageInUrl)
    );
    setStaticDataItem(matchedData);
  }, [pageInUrl]);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const closeFullscreen = (e) => {
    if (e.target === e.currentTarget) { // Checks if the click is outside the image
      setIsFullscreen(false);
    }
  };

  if (!staticDataItem) {
    return <div className="container staticPageContainer">Page not found</div>;
  }

  return (
    <div className='container staticPageContainer'>
      <div className="sPageTitle Jomolhari-Bold">{staticDataItem.staticPageName}</div>
      {staticDataItem.staticPageImg && (
        <div className="sPageImageContainer" onClick={toggleFullscreen}>
          <img src={staticDataItem.staticPageImg} alt={staticDataItem.staticPageName} className="staticPageImage" />
        </div>
      )}
      {isFullscreen && (
        <div className="fullscreenContainer" onClick={closeFullscreen}>
          <img src={staticDataItem.staticPageImg} alt="Fullscreen" className="fullscreenImage" />
          <svg className="closeIcon" onClick={() => setIsFullscreen(false)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      )}
      <div className="sPageParagraph">
        <div className="sParagraphWrapper">
          {staticDataItem.staticSection.map((section) => (
            <div
              key={section.pgId}
              className="blogPageParagraph"
              dangerouslySetInnerHTML={{ __html: section.pgDesc }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StaticPage;
