
import React, { useState, useEffect, useRef } from 'react';
import products from '../../JsonFiles/squareProductsCarousel.json';
import '../../Styles/SquareProductsCarousel.css';

export default function SimpleImageCarousel({
  images,
}) {
  const [matchingImages, setMatchingImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const carouselRef = useRef(null);

  // useEffect(() => {
  //   const matchedProducts = products.filter((product) =>
  //     Number(product.productBlogId) === Number(blogId)
  //   );
  //   // Load all images without filtering by sectionTag
  //   const images = matchedProducts.flatMap(product =>
  //     product.productImages.map(image => image.imageLink)
  //   );

  //   setMatchingImages(images);
  // }, []); // No dependency on sectionTag

  // useEffect(()=>{
  //   console.log(matchingImages);
  // },[matchingImages])

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
    e.currentTarget.style.cursor = 'grabbing';
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 0.5; // Adjust scroll speed as needed
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: 1005, // Adjust value as per your design
        behavior: 'smooth',
      });
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -1005, // Adjust value as per your design
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="squareCarouselContainer container">

      <div
        className="squareCarouselProductsContainer"
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        ref={carouselRef}
      >
        <div className="squareCarouselControls">
          <button className="prevButton Inter" onClick={handlePrev}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            width={24}
            height={24}
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            class="feather feather-arrow-left"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
          </button>
          <button className="nextButton Inter" onClick={handleNext}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"           
            width={24}
            height={24}
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            class="feather feather-arrow-right"
          >
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <polyline points="12 5 19 12 12 19"></polyline>
          </svg>
          </button>
        </div>
        {images.map((image, index) => (
          <div className="squareCarouselProductContainer" key={index}>
                         <div className="sliders"></div>
            <div className="squareImageContainer">
              <img
                src={`${image?.attributes?.url}`}
                alt={`Product ${image?.id}`}
                className="squareImage"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
