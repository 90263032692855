import { useState } from "react";
import { Link } from "react-router-dom";

const Create = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    offers: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with:", formData);
  };
  return (
    <div className="container loginContainer">
      <div className="loginLeftContainer">
        <div className="createLeft">
          <h2 className="loginTitle Jomolhari">JOIN US</h2>
          <p className="loginSubtitle">
            Join our Komhendo Club! Earn points, redeem in-store credits, enjoy
            member-only discounts & perks, and more!
          </p>
          <button className="loginGoogle">Continue with Google</button>
          <div className="loginSeparator">
            <span></span>OR<span></span>
          </div>
          <form className="loginForm" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="firstName">
                First name<span>*</span>
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="lastName">Last name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="email">
                Email<span>*</span>
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="password">
                Password<span>*</span>
              </label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="confirmPassword">
                Confirm password<span>*</span>
              </label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
            <div className="checkboxContainer">
              <input
                type="checkbox"
                name="offers"
                checked={formData.offers}
                onChange={handleChange}
              />
              <label className="offers" htmlFor="offers">
                Email me
              </label>
            </div>
            <p>
              Subscribe and be the first to know our sales, new arrivals,
              exclusive events and more!
            </p>
            <button type="submit">Join Now</button>
          </form>
        </div>
      </div>
      <div className="loginRightContainer"></div>
    </div>
  );
};
export default Create;
