import { customAxios } from "./customAxios";

export const getCatalogue = async () => {
  try {
    const { data } = await customAxios.get("/catalogue?populate=*");
    return data;
  } catch (error) {
    console.log(error);
  }
};
