import { Navigate, Outlet, ScrollRestoration } from "react-router-dom";
import Footer from "../Components/Common/Footer";
import NavBar from "../Components/Common/NavBar";
import Sidebar from "../Components/Common/Sidebar";
import "../Styles/NavBar.css";
import "../Styles/SmNavBar.css";
import "../Styles/AboutUs.css";
import "../Styles/Menu.css";
import "../Styles/Layout.css";
import "../Styles/Dropdown.css";
import "../Styles/AdsBanner.css";
import "../Styles/Gradients.css";
import "../Styles/Footer.css";
import "../Styles/SmFooter.css";
import "../Styles/StaticPage.css";
import "../Styles/Cart.css";
import "../Styles/CartPage.css";
import "../Styles/Login.css";
import { useCartStore, useHomeStore } from "../Store";
import Dropdown from "../Components/Common/Dropdown";
import { useEffect, useState } from "react";
import SmNavBar from "../Components/Common/SmNavBar";
import SmFooter from "../Components/Common/SmFooter";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KomhendoBanner from "../Components/Common/AdsBanner";

const Layout = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sidebar = useHomeStore((state) => state.sidebar);
  const dropdowns = useHomeStore((state) => state.dropdowns);


  const { fetchCart } = useCartStore.getState();

  useEffect(() => {
    // Fetch the cart when the app loads
    fetchCart();
  }, [fetchCart]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className={`Inter ${sidebar || dropdowns ? `menuShown` : ``}`}>
        {windowWidth >= 1000 ? <NavBar /> : <SmNavBar />}
        <Dropdown />
        <main>
          <ScrollRestoration />
          <Outlet />
        </main>
        <KomhendoBanner />
        {windowWidth >= 767 ? <Footer /> : <SmFooter />}
      </div>
      <Sidebar />
    </>
  );
};

export default Layout;
