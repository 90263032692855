// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smNavBarContainer{
  font-size:16px;
  width:100%;
  position: sticky;
  top:0;
  z-index:10;
  height:7.29rem;
  background: white;
  display: flex;
  align-items: center;
}

.smNavBarLogoLeft{
  font-size:1.3125em;
  display: inline-flex;
  position: absolute;
  left:0.76190em;
}

.smKomhendoLogoImage{
  width:90px;
}

.smNavBarItemsRight{
  position: absolute;
  display: flex;
  right:1em;
  gap:2em;
}

@media screen and (max-width:428px) {
  .smNavBarContainer{
    font-size:3.738vw;
  }
}

.list-none{
  list-style: none;
}`, "",{"version":3,"sources":["webpack://./src/Styles/SmNavBar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;EACV,gBAAgB;EAChB,KAAK;EACL,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,OAAO;AACT;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".smNavBarContainer{\r\n  font-size:16px;\r\n  width:100%;\r\n  position: sticky;\r\n  top:0;\r\n  z-index:10;\r\n  height:7.29rem;\r\n  background: white;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.smNavBarLogoLeft{\r\n  font-size:1.3125em;\r\n  display: inline-flex;\r\n  position: absolute;\r\n  left:0.76190em;\r\n}\r\n\r\n.smKomhendoLogoImage{\r\n  width:90px;\r\n}\r\n\r\n.smNavBarItemsRight{\r\n  position: absolute;\r\n  display: flex;\r\n  right:1em;\r\n  gap:2em;\r\n}\r\n\r\n@media screen and (max-width:428px) {\r\n  .smNavBarContainer{\r\n    font-size:3.738vw;\r\n  }\r\n}\r\n\r\n.list-none{\r\n  list-style: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
