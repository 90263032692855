import { useState } from "react";
import { useHomeStore } from "../Store";
import SearchIcon from "../Icons/SearchKomhendo.svg";
import data from "../JsonFiles/popular.json";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../Icons/CloseKomhendo.svg";

//check Dropdown.css for modifying css in this page

const Search = () => {
  const navigate = useNavigate();
  const [searchField, setSearchField] = useState("");
  const setDropdowns = useHomeStore((state) => state.setDropdowns);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchField === "") return;
    setDropdowns(0);
    navigate("/search", { state: { searchField } });
  };

  const clickSubmission = () => {
    if (searchField === "") {
      setSearchField("");
      setDropdowns(0);
      return;
    }
    setDropdowns(0);
    navigate("/search", { state: { searchField } });
  };

  return (
    <div className="searchContainer container">
      <div className="smSearchContainerHeader">
        <div className="smSearchContainerHeaderLeft Jomolhari">Search</div>
        <div className="smSearchContainerHeaderRight">
          <img
            src={CloseIcon}
            alt="CloseSearch"
            className="closeSearch"
            onClick={() => {
              setSearchField("");
              setDropdowns(0);
            }}
          />
        </div>
      </div>
      <form id="search" className="searchbarForm" onSubmit={handleSubmit}>
        <div className="searchbarContainer">
          <img src={SearchIcon} alt="search" />
          <input
            className="searchbar"
            type="text"
            placeholder="What are you looking for ?"
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
          />
          <p onClick={clickSubmission}>
            {searchField === "" ? "| Cancel" : "| Search"}
          </p>
        </div>
        <hr />
      </form>
      <div className="searchResultContainer">
        <h3 className="searchTitle Jomolhari">Popular Searches</h3>
        <div className="searchResults">
          {data.map((image, index) => (
            <div className={image.imageType} key={index}>
              <img src={image.imageLink} alt={image.imageTag} />
              <p>{image.imageTag}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Search;
