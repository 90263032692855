import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imageData from "../../JsonFiles/HeroSectionImages.json";
import { importImage } from "../../miscellaneousFunc";
import { getLayout, getMultiImageLayoutImage, getSingleImageLayoutImage } from "../../Api/layout";


const HeroSection = () => {
  const navigate = useNavigate();
  // const [images, setImages] = useState(imageData.images);
  const [imageDimensions, setImageDimensions] = useState([]);
  const [key, setKey] = useState(0); // Key for remounting
  const [mountDetailsWrapper, setMountDetailsWrapper] = useState(false);

  const [images, setImages] = useState([]);

  const [layout, setLayout] = useState("oldLayout");

  useEffect(() => {
    const images = document.querySelectorAll(".image-gallery img");
    const dimensions = Array.from(images).map((img) => ({
      width: img.naturalWidth,
      height: img.naturalHeight,
    }));
    setImageDimensions(dimensions);
  }, [images]);

  const handleImageLoad = (index) => {
    // Check if all image dimensions are non-zero
    const allDimensionsNonZero = imageDimensions.every(
      (dim) => dim.width !== 0 || dim.height !== 0
    );
    if (allDimensionsNonZero) {
      setMountDetailsWrapper(true);
      setKey((prevKey) => prevKey + 1); // Update key to force remount
    }
  };

  const getLayouts = async () => {
    const response = await getLayout();
    // console.log("layoutOption", response?.data?.data?.attributes?.layoutoptions)

    setLayout(response?.data?.data?.attributes?.layoutoptions)

    if (response?.data?.data?.attributes?.layoutoptions === "newLayout") {
      const response = await getSingleImageLayoutImage();
      // console.log(response?.data)
      // console.log(response?.data?.data?.attributes?.image?.data)
      setImages(response?.data?.data?.attributes?.image?.data)
      // console.log(images)
    } else if (response?.data?.data?.attributes?.layoutoptions === "oldLayout") {
      const response = await getMultiImageLayoutImage();
      // console.log(response?.data)
      // console.log(response?.data?.data?.attributes)
      setImages(response?.data?.data?.attributes)
    }

  }

  useEffect(() => {
    getLayouts();
  }, [])

  if (layout === "newLayout") {
    // return <>
    //   
    return <>
      {
        images?.length === 1 ? <div
          className="container image-gallery"
          style={{
            backgroundImage: `
              url(${images[0]?.attributes?.url})
            `,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div> : <div className="container image-gallery" >
          {
            images?.map((image, index) => (
              <div className="image-wrapper" key={image.id}>

                <img className="imageHeightSetter" src={`${image?.attributes?.url}`} alt={`Image ${image.id}`} />
              </div>
            ))
          }

        </div>
      }
    </>
  }

  if (layout === "oldLayout") {
    return <>
      <div className="container image-gallery" >
        {
          images?.images?.data?.map((image, index) => (
            <div className="image-wrapper" key={image.id}>
              {
                <div className="detailsWrapper">
                  {index === 1 && images[0] !== "" && (
                    <div className="imgTagOne Inter">{images?.title}</div>
                  )}
                  {index === 1 && image?.imgTagTwo !== "" && (
                    <div className="imgTagTwo Inter">{images?.collection?.data?.attributes?.collectionName}</div>
                  )}
                  {index === 1 && image?.buttonText !== "" && (
                    <button
                      className={`primary Inter-Medium heroSectionButton`}
                      onClick={() =>
                        navigate(
                          `/collection/${images?.collection?.data?.id}`
                        )
                      }
                    >
                      {`Explore the Collection`}
                    </button>
                  )}
                </div>
              }

              {index === 1 && <div className={`gradient imageHeightSetter`}></div>}

              <img className="imageHeightSetter" src={`${image?.attributes?.url}`} alt={`Image ${image.id}`} />
            </div>
          ))
        }

      </div>
    </>
  }

  return (
    // <div
    //   className="container image-gallery"
    //   style={{
    //     backgroundImage: `
    //       url('../Images/hero.jpg')
    //     `,
    //     backgroundSize: 'cover',  
    //     backgroundPosition: 'center',
    //     backgroundRepeat: 'no-repeat',
    //   }}
    // >
    <>

      {/* {layout === 1 ? <div
        className="container image-gallery"
        style={{
          backgroundImage: `
            url('../Images/hero.jpg')
          `,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      ></div> :
        <div className="container image-gallery" >
          {
            images.map((image, index) => (
              <div className="image-wrapper" key={image.id}>
                {
                  <div className="detailsWrapper">
                    {image.imgTagOne !== "" && (
                      <div className="imgTagOne Inter">{image.imgTagOne}</div>
                    )}
                    {image.imgTagTwo !== "" && (
                      <div className="imgTagTwo Inter">{image.imgTagTwo}</div>
                    )}
                    {image.buttonText !== "" && (
                      <button
                        className={`${image.buttonState} Inter-Medium heroSectionButton`}
                        onClick={() =>
                          navigate(
                            `/collection/1`
                          )
                        }
                      >
                        {image.buttonText}
                      </button>
                    )}
                  </div>
                }
                {image.className !== "" && (
                  <div className={`${image.className} imageHeightSetter`}></div>
                )}
                <img className="imageHeightSetter" src={importImage(image.src)} alt={`Image ${image.id}`} />
              </div>
            ))
          }

        </div>} */}
      Loading ...
    </>
  );
};

export default HeroSection;
