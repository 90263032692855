import { useState } from "react";
import { Link } from "react-router-dom";
import { customAxios } from "../../Api/customAxios";
import { registerUser } from "../../Api/auth";

function JoinUs({ handleTabChange }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    offers: false,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Set errors if validation fails
      return;
    }

    try {
      const response = await registerUser(formData)
      // console.log(response);
      // console.log("Form submitted with:", formData);
      handleTabChange(1)
    } catch (error) {
      console.error("Error during form submission:", error);
      if (error.response) {
        const serverErrors = error.response.data.error.message;

        if (serverErrors.includes("Email already exists")) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Email is already registered.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            general: "An unexpected error occurred. Please try again.",
          }));
        }
      } else {
        console.error("Unexpected error during signup:", error);
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Network error. Please check your connection.",
        }));
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate First Name and Last Name
    if (!formData.firstName.trim()) newErrors.firstName = "First name is required.";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required.";


    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) newErrors.email = "Invalid email address.";

    // Validate Password and Confirm Password
    if (formData.password.length < 8) newErrors.password = "Password must be at least 8 characters long.";
    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = "Passwords do not match.";

    return newErrors;
  };

  return (
    <form className="sidebarForm" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="firstName">
          First name<span>*</span>
        </label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
        {errors.firstName && <p className="error">{errors.firstName}</p>}
      </div>
      <div>
        <label htmlFor="lastName">Last name</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
        {errors.lastName && <p className="error">{errors.lastName}</p>}
      </div>
      <div>
        <label htmlFor="email">
          Email<span>*</span>
        </label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        {errors.email && <p className="error">{errors.email}</p>}
      </div>
      <div>
        <label htmlFor="password">
          Password<span>*</span>
        </label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        {errors.password && <p className="error">{errors.password}</p>}
      </div>
      <div>
        <label htmlFor="confirmPassword">
          Confirm password<span>*</span>
        </label>
        <input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
        {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
      </div>

      <div className="checkboxContainer">
        <input
          type="checkbox"
          name="offers"
          checked={formData.offers}
          onChange={handleChange}
        />
        <label className="offers" htmlFor="offers">
          I'd like to receive exclusive offers and news.
        </label>
      </div>
      <button type="submit">Join Now</button>
      <p className="moreLinks">
        By clicking 'Join Now', you agree to KOMHENDO's{" "}
        <Link>Terms & Conditions</Link> and <Link>Privacy Policy.</Link>
      </p>
    </form>
  );
}
export default JoinUs;
