import Select from "react-select";
import "../Styles/ContactUs.css";
import { useEffect, useState } from "react";
import { scrollToTop } from "../miscellaneousFunc";

const ContactUs = () => {
  useEffect(() => scrollToTop(), []);
  const [selectValue, setSelectValue] = useState(null);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    message: "",
  });
  const [focused, setFocused] = useState({
    firstName: false,
    lastName: false,
    email: false,
    contactNumber: false,
    message: false,
  });

  const selectOptions = [
    { value: "bulk-orders", label: "Bulk Orders" },
    { value: "about-product", label: "About Product" },
    { value: "other", label: "Other" },
  ];

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFocus = (name) => {
    setFocused({ ...focused, [name]: true });
  };

  const handleBlur = (name) => {
    if (!formValues[name]) {
      // Only unset focus if the field is empty
      setFocused({ ...focused, [name]: false });
    }
  };

  const getPlaceholder = (name) => {
    if (!focused[name] && !formValues[name]) {
      switch (name) {
        case "firstName":
          return "First Name";
        case "lastName":
          return "Last Name";
        case "email":
          return "Email";
        case "contactNumber":
          return "Contact Number";
        case "message":
          return "Message";
        default:
          return "";
      }
    }
    return "";
  };

  const submitForm = () => {
    if (
      !(
        selectValue &&
        formValues.firstName &&
        formValues.lastName &&
        formValues.email &&
        formValues.contactNumber &&
        formValues.message
      )
    )
      return;
    console.log({ ...formValues, value: selectValue?.value });
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      message: "",
    });
    setSelectValue(null);
    setFocused({
      firstName: false,
      lastName: false,
      email: false,
      contactNumber: false,
      message: false,
    });
  };

  return (
    <div className="container contactUsContainer">
      <div className="contactUsBanner">
        <h3 className="Inter ">Need Help?</h3>
        <h1 className="Jomolhari">Contact Us</h1>
      </div>

      <div className="contactUsText">
        <p className="Jomolhari">
          At Ko_Mhendo, we are proud to offer highest quality products and
          provide the best customer services. We also understand sometimes
          things happen and we are always here to help.
        </p>
      </div>

      <div className="formContainer">
        <div className="indivForm">
          <label
            htmlFor="firstName"
            className={focused.firstName ? "label visible" : "label"}
          >
            First Name
          </label>
          <input
            name="firstName"
            value={formValues.firstName}
            onChange={handleFormChange}
            type="text"
            onFocus={() => handleFocus("firstName")}
            onBlur={() => handleBlur("firstName")}
            placeholder={getPlaceholder("firstName")}
          />
        </div>
        <div className="indivForm">
          <label
            htmlFor="lastName"
            className={focused.lastName ? "label visible" : "label"}
          >
            Last Name
          </label>
          <input
            name="lastName"
            value={formValues.lastName}
            onChange={handleFormChange}
            type="text"
            placeholder={getPlaceholder("lastName")}
            onFocus={() => handleFocus("lastName")}
            onBlur={() => handleBlur("lastName")}
          />
        </div>
        <div className="indivForm">
          <label
            htmlFor="email"
            className={focused.email ? "label visible" : "label"}
          >
            Email
          </label>
          <input
            name="email"
            value={formValues.email}
            onChange={handleFormChange}
            type="email"
            placeholder={getPlaceholder("email")}
            onFocus={() => handleFocus("email")}
            onBlur={() => handleBlur("email")}
          />
        </div>
        <div className="indivForm">
          <Select
            options={selectOptions}
            value={selectValue}
            placeholder="Select a Subject"
            unstyled
            onChange={setSelectValue}
            className="selectContainer"
            isSearchable={false}
            classNamePrefix="select"
            styles={{
              control: (provided) => ({
                ...provided,
                color: "black", // This sets the text color
                fontSize: "1.375rem"
              }),
              placeholder: (provided) => ({
                ...provided,
                color: "#9a9a9a", // This sets the placeholder color
                fontSize: "1.375rem"
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "black", // This ensures selected value text is black
                fontSize: "1.375rem"
              }),
            }}
          />
        </div>
        <div className="indivForm">
          <label
            htmlFor="contactNumber"
            className={focused.contactNumber ? "label visible" : "label"}
          >
            Contact Number
          </label>
          <input
            name="contactNumber"
            value={formValues.contactNumber}
            onChange={handleFormChange}
            type="text"
            placeholder={getPlaceholder("contactNumber")}
            onFocus={() => handleFocus("contactNumber")}
            onBlur={() => handleBlur("contactNumber")}
          />
        </div>
        <div className="indivForm">
          <label
            htmlFor="message"
            className={focused.message ? "label visible" : "label"}
          >
            Message
          </label>
          <input
            name="message"
            value={formValues.message}
            onChange={handleFormChange}
            type="text"
            placeholder={getPlaceholder("message")}
            onFocus={() => handleFocus("message")}
            onBlur={() => handleBlur("message")}
          ></input>
        </div>
        <div className="buttonContainer">
          <button
            type="submit"
            className="sendMessageButton"
            onClick={submitForm}
          >
            Send Message
          </button>
        </div>
      </div>
      <div className="contactUsInfo Inter">
        <h1 className="contactUsInfoTitle Jomolhari">Contact Address</h1>
        <div className="contactUsDetails">
          <div className="detailsLeft">
            <h4>Head Office</h4>
            <p>Ko_Mhendo P. Ltd.</p>
            <p>Budanilkantha</p>
            <p>Kathmandu, 44600, NEPAL</p>
            <p>Email: sales@komhendo.com</p>
          </div>
          <div className="detailsMap">
            <iframe
              width="100%"
              height="290"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Budanilkantha,%20Kathmandu,%2044600,%20Nepal+(KoMhendo%20P.%20Ltd.)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps trackers</a>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
