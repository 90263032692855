// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adsBannerContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:1.111vw;
}

.adImage{
  padding-top:3.5em;
  padding-bottom:3.5em;
  width:78.75em;
}

.adImageSm{
  display:none;
}

@media screen and (min-width:1440px) {
  .adsBannerContainer{
    font-size: 16px;
  }
}

@media screen and (max-width:767px) {
  .adsBannerContainer{
    font-size: 3.738vw;
  }
  .adImage{
    display: none;
  }
  .adImageSm{
    display:flex;
    padding-top:1em;
    padding-bottom:1em;
    width:24.75em;
  }
}
@media screen and (max-width:428px) {
  .adsBannerContainer{
    font-size: 3.738vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Styles/AdsBanner.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,aAAa;EACf;EACA;IACE,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,aAAa;EACf;AACF;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".adsBannerContainer{\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-size:1.111vw;\r\n}\r\n\r\n.adImage{\r\n  padding-top:3.5em;\r\n  padding-bottom:3.5em;\r\n  width:78.75em;\r\n}\r\n\r\n.adImageSm{\r\n  display:none;\r\n}\r\n\r\n@media screen and (min-width:1440px) {\r\n  .adsBannerContainer{\r\n    font-size: 16px;\r\n  }\r\n}\r\n\r\n@media screen and (max-width:767px) {\r\n  .adsBannerContainer{\r\n    font-size: 3.738vw;\r\n  }\r\n  .adImage{\r\n    display: none;\r\n  }\r\n  .adImageSm{\r\n    display:flex;\r\n    padding-top:1em;\r\n    padding-bottom:1em;\r\n    width:24.75em;\r\n  }\r\n}\r\n@media screen and (max-width:428px) {\r\n  .adsBannerContainer{\r\n    font-size: 3.738vw;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
