import { protectedAxios } from "./protectedAxios";

export const stripeCheckout = async (cartItem) => {
  try {
    const { data } = await protectedAxios.post("/orders/stripe", cartItem);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const paypalCheckout = async (cartItem) => {
  try {
    const { data } = await protectedAxios.post("/orders/paypal", cartItem);
    return data;
  } catch (error) {
    console.log(error);
  }
};
