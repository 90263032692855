import { useState } from "react";
import { Link } from "react-router-dom";

function Login() {

  
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with:", formData);
  };
  return (
    <div className="container loginContainer">
      <div className="loginLeftContainer">
        <div className="loginLeft">
          <h2 className="loginTitle Jomolhari">Member Login </h2>
          <p className="loginSubtitle">
            If you have an account, sign in with your email address.
          </p>
          <button className="loginGoogle">Login with Google</button>
          <div className="loginSeparator">
            <span></span>OR<span></span>
          </div>
          <form className="loginForm" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>

            <div className="forgotPassword">
              <Link to="/">Forgot password?</Link>
            </div>

            <button type="submit">Login</button>
          </form>
        </div>
      </div>
      <div className="loginRightContainer">
        <div className="loginRight">
          <h2 className="loginTitle Jomolhari">New Customers</h2>
          <p className="loginSubtitle">
            Join our Komhendo Club! Earn points, redeem in-store credits, enjoy
            member-only discounts & perks, and more!
          </p>
          <Link to="/auth/create">
            <button>Join Now</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Login;
