import axios from "axios";
import { useAuthStore } from "../Store";

// Create axios instance for protected routes
export const protectedAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`,
  withCredentials: true, // Enable sending cookies with requests
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

protectedAxios.interceptors.request.use(
    (config) => {
      // Retrieve the JWT token from cookies
      const token = document.cookie
        .split('; ')
        .find(row => row.startsWith('jwt='))?.split('=')[1]; // Look for 'jwt' cookie name
      
      // If token exists, add the Authorization header
      if (token) {
        // console.log(token)
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );