import { customAxios } from "./customAxios";

export const getBlogsForHome = async () => {
  try {
    const { data } = await customAxios.get(
      "/blogs?populate[smHeroImage]=*&fields[0]=blogTitle&fields[1]=id&fields[2]=blogTitleImageType"
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getBlogs = async () => {
  try {
    const { data } = await customAxios.get("/blogs?populate=*");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getBlogsById = async (id) => {
  try {
    const { data } = await customAxios.get(`/blogs/${id}?populate=*`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
