import { useHomeStore } from "../../Store";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import jsonData from "../../JsonFiles/dropDownImages.json";
import Search from "../Search";
import { getCollections } from "../../Api/collections";

const Dropdown = () => {
  const navigate = useNavigate();
  const dropdowns = useHomeStore((state) => state.dropdowns);
  const setDropdowns = useHomeStore((state) => state.setDropdowns);
  const [isAnimating, setIsAnimating] = useState(false);


  const [collections, setCollections] = useState()

  const getMyCollections = async () => {
    const data = await getCollections();
    // console.log(data?.data)
    setCollections(data?.data)
  }

  useEffect(() => {
    getMyCollections();
  }, [])


  const DropdownItem = ({ data }) => {
    return (
      <div className="dropdownItem">
        <div className="dropdownLeft">
          {/* {collections.map((data,index)=>( */}
          <div key={data?.id} className="section">
            <h2 className="dropdownTitle Jomolhari">Wardrobe</h2>
            <hr />
            <div className="dropdownMenu">
              {collections?.map((menuItem, index) => (
                <h6 key={index}>
                  <Link
                    to={`/collection/${menuItem.id}`}
                    onClick={() => setDropdowns(0)}
                  >
                    {menuItem?.attributes?.collectionName}
                  </Link>
                </h6>
              ))}
            </div>
          </div>
          {/*  ))} */}
        </div>
        <div className="dropdownRight">
          {collections?.map((item, index) => (
            <div
              className={item?.id}
              key={index}
              onClick={() => {
                navigate(
                  `/collection/${item.id}`
                );
                setDropdowns(0);
              }}
            >
              {
                item?.attributes?.image?.data?.length > 0 &&
                <img className={item?.id} src={`${item?.attributes?.image?.data[0]?.attributes?.url}`} alt={item?.attributes?.collectionName} />
              }
              <p>{item?.attributes?.collectionName}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const dropdownEl = () => {
    switch (dropdowns) {
      case 1:
        return <DropdownItem data={jsonData.Collection} />;
      case 2:
        return <DropdownItem data={jsonData.Pashmina} />;
      case 3:
        return <DropdownItem data={jsonData.Cashmere} />;
      case 4:
        return <Search />;
      default:
        return <></>;
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) setDropdowns(0);
  };

  useEffect(() => {
    if (dropdowns) setIsAnimating(true);
    else {
      const animationTime = setTimeout(() => setIsAnimating(false), 500);
      return () => clearTimeout(animationTime);
    }
  }, [dropdowns]);

  return (
    <div
      className={`dropdownContainer ${dropdowns
        ? "dropdownVisible"
        : isAnimating
          ? "dropdownFading"
          : "dropdownHidden"
        }`}
      onClick={handleOutsideClick}
    >
      <div
        className={`dropdown ${dropdowns === 4 ? "searchHeightAdjuster" : ""}`}
      >
        <div className="container">{dropdownEl()}</div>
      </div>
    </div>
  );
};
export default Dropdown;
