import { protectedAxios } from "./protectedAxios";

export const getCart = async () => {
  try {
    const { data } = await protectedAxios.get("/carts");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const clearCart = async () => {
  try {
    const { data } = await protectedAxios.delete("/api/carts");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const removeCartItem = async (id, productColorOption, productSize) => {
  try {
    const { data } = await protectedAxios.put(`/carts/${id}`, {
      data: {
        product: id,
        colorOption: productColorOption,
        productSize: productSize,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const putItemInCart = async (
  id,
  productColorOption,
  productSize,
  quantity
) => {
  // console.log(id, quantity)
  try {
    const { data } = await protectedAxios.post("/carts", {
      data: {
        product: id,
        quantity: quantity, // Adjust the quantity as needed
        productColorOption: productColorOption,
        productSize: productSize,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateCartOnBackend = async (id, quantity) => {
  // console.log(id, quantity)
  try {
    const { data } = await protectedAxios.post("/carts", {
      data: {
        product: id,
        quantity: quantity, // Adjust the quantity as needed
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const syncCartToBackend = async (cartItems) => {
  try {
    for (const item of cartItems) {
      // Structure your API payload
      const payload = {
        data: {
          product: item.product.id,
          quantity: item.quantity,
          productColorOption: item.productColorOption,
          productSize: item.productSize,
        },
      };
      console.log(payload);
      // Send the item to the backend
      await protectedAxios.post("/carts", payload);
    }

    console.log("Cart items successfully synced to the backend.");
  } catch (error) {
    console.error(
      "Error syncing cart items:",
      error.response?.data || error.message
    );
  }
};
