import React from 'react';
import { Link } from 'react-router-dom';

export default function AboutUs() {
  return (
    <div className="aboutBlock container">
      <div className="aboutHeader Jomolhari  text-center">
        <span className='aboutHeaderSecond Jomolhari'>The Ko_Mhendo difference!</span>
        <br />
        <span className='Inter aboutSubHeading' ><Link to={"/blogs?blogId=3"}>Crafting Excellence, Sustaining Tomorrow</Link></span>
      </div>
      <div className="smAboutHeader Jomolhari  text-center">
        <span className="smAboutHeaderSecond" >

          The Ko_Mhendo difference!
          <br />
        </span>
        <span className='Inter ' ><Link to={"/blogs?blogId=3"}>Crafting Excellence, Sustaining Tomorrow</Link></span>
      </div>
    </div>
  );
}
