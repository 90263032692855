import { useState } from "react";
import { Link } from "react-router-dom";
import FavIcon from "../Icons/FavKomhendo.svg";

const CollectionItem = ({ id, image, name }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => {
    setIsHovering(false);
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 300);
  };

  return (
    <Link
      style={{
        backgroundImage: `url(${image})`,
      }}
      className="collectionItem"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      to={`/products?productId=${id}`}
    >
      {/* <Link
        className={`iconContainer ${
          isHovering
            ? "itemNameVisible"
            : isAnimating
            ? "itemNameFading"
            : "itemNameHidden"
        }`}
      >
        <img className="favIcon" src={FavIcon} alt="favicon" />
      </Link> */}
      <div
        className={`itemName ${
          isHovering
            ? "itemNameVisible"
            : isAnimating
            ? "itemNameFading"
            : "itemNameHidden"
        }`}

      >
        {name}
      </div>
    </Link>
  );
};
export default CollectionItem;
