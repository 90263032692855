import { useState } from "react";
import { Link } from "react-router-dom";
import { customAxios } from "../../Api/customAxios";
import { useAuthStore, useHomeStore } from "../../Store";
import { loginUser } from "../../Api/auth";
import { handleCartSync } from "../../utils/handleCartSync";

function SignIn() {

  const login = useAuthStore((state) => state.login);
  const { redirectPath, setRedirectPath } = useHomeStore();
  const setSidebar = useHomeStore((state) => state.setSidebar);


  const [formData, setFormData] = useState({
    identifier: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    identifier: "",
    password: "",
    general: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setErrors({ identifier: "", password: "", general: "" });

    if (!formData.identifier || !formData.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        identifier: !formData.identifier ? "Email is required" : "",
        password: !formData.password ? "Password is required" : "",
      }));
      setLoading(false);
      return;
    }

    try {
      const response = await loginUser(formData);
      console.log("Login successful:", response);
      login(response)

      handleCartSync();

      if (redirectPath) {
        setSidebar(redirectPath)
        setRedirectPath(null);  // Clear the stored path after redirection
      }




      // Redirect user or perform desired actions on successful login
    } catch (error) {
      console.error("Error during login:", error);

      // Handle server-side error response
      if (error.response) {
        const { message } = error.response.data.error || {};
        if (message.includes("Invalid Email or password")) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            general: "Invalid Email or password",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            general: "An unexpected error occurred. Please try again.",
          }));
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Unable to connect to the server. Please check your network.",
        }));
      }
    } finally {
      setLoading(false);
    }

  };

  return (
    <form className="sidebarForm" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="identifier">Email</label>
        <input
          type="email"
          name="identifier"
          value={formData.identifier}
          onChange={handleChange}
          required
        />
        {errors.identifier && <p className="errorMessage">{errors.identifier}</p>}
      </div>

      <div>
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        {errors.password && <p className="errorMessage">{errors.password}</p>}
      </div>

      <div className="forgotPassword">
        <Link to="/">Forgot password?</Link>
      </div>
      <button type="submit">Sign In</button>
      <p className="moreLinks">
        Your privacy is important to us. See Our <Link>Privacy Policy.</Link>
      </p>
    </form>
  );
}
export default SignIn;
