import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import Home from "./Pages/Home";
import Layout from "./Pages/Layout";
import ContactUs from "./Pages/ContactUs";
import IndividualCollection from "./Pages/IndividualCollection";
import Products from "./Pages/Products";
import BlogPage from "./Pages/BlogPage";
import SearchResults from "./Pages/SearchResults";
import StaticPage from "./Pages/StaticPage";
import Login from "./Pages/Login";
import Create from "./Pages/Create";
import CartPage from "./Pages/CartPage";

export const mainRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" Component={Layout}>
      <Route index Component={Home}></Route>
      <Route path="/contact-us" Component={ContactUs}></Route>
      <Route path="/blogs" Component={BlogPage}></Route>
      <Route path="/collection/:name" Component={IndividualCollection}></Route>
      <Route path="/products" Component={Products}></Route>
      <Route path="/search" Component={SearchResults}></Route>
      <Route path="/sPage/:page" Component={StaticPage}></Route>
      <Route path="/auth/login" Component={Login}></Route>
      <Route path="/auth/create" Component={Create}></Route>
      <Route path="/checkout/cart" Component={CartPage}></Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
);
