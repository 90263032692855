import { useAuthStore, useCartStore, useHomeStore } from "../../Store";
import CloseIcon from "../../Icons/CloseKomhendo.svg";

import { useEffect, useState } from "react";
import Menu from "./Menu";
import Account from "./Account";
import Cart from "./Cart";

const Sidebar = () => {
  const sidebar = useHomeStore((state) => state.sidebar);
  const setSidebar = useHomeStore((state) => state.setSidebar);
  const cartItemsNumber = useCartStore((state) => state.cartItemsNumber);
  const [isAnimating, setIsAnimating] = useState(false);

  const user = useAuthStore((state) => state.user)


  useEffect(() => {
    if (sidebar) {
      setIsAnimating(true);
    } else {
      const animationTime = setTimeout(() => setIsAnimating(false), 500);
      return () => clearTimeout(animationTime);
    }
  }, [sidebar]);

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) setSidebar(0);
  };

  const menuEl = () => {
    switch (sidebar) {
      case 1: {
        return <Menu />;
      }
      case 2:
        return <Account />;
      case 3:
        return <Cart />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`Inter menuContainer ${sidebar ? "menuVisible" : isAnimating ? "menuFading" : "menuHidden"
        }`}
      onClick={handleOutsideClick}
    >
      <div className="menuElementContainer">
        <div className="menuTopContainer">
          <div className="menuTop">
            <h3 className="menuTitle Jomolhari">
              {sidebar === 1
                ? "Menu"
                : sidebar === 2
                  ? "Account"
                  : sidebar === 3
                    ? "Cart"
                    : ""}
              {sidebar === 3 && (
                <span className="cartItemsNo"> ( {cartItemsNumber} ) {` `}{user?.user?.firstName} </span>
              )}
            </h3>
            <img
              src={CloseIcon}
              className="menuClose"
              alt="close"
              onClick={() => setSidebar(0)}
            />
          </div>
        </div>
        <div className="menuMain">{menuEl()}</div>
      </div>
    </div>
  );
};
export default Sidebar;
