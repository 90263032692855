import { customAxios } from "./customAxios";

export const getLayout = async () => {
  try {
    const response = await customAxios.get("/site-layout");
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getSingleImageLayoutImage = async () => {
  try {
    const response = await customAxios.get("/single-image-layout?populate=*");
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getMultiImageLayoutImage = async () => {
  try {
    const response = await customAxios.get("/multi-image-layout?populate=*");
    return response;
  } catch (error) {
    console.log(error);
  }
};

