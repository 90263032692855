import React, { useEffect, useState } from 'react'
import { importImage } from '../../miscellaneousFunc'
import { getBannerImage } from '../../Api/banner'


export default function KomhendoBanner() {

  const [bannerImage, setBannerImage] = useState(null)

  const getMyBannerImage = async () => {
    const data = await getBannerImage();
    setBannerImage(data?.data?.data?.attributes?.image?.data?.attributes?.url)

  }

  useEffect(() => {
    getMyBannerImage()
  }, [])

  return (
    <div className='container adsBannerContainer'>
      <img className='adImage' src={`${bannerImage}`} alt="adImage" />
      <img className="adImageSm" src={`${bannerImage}`} alt="" />
    </div>
  )
}
